/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransferInstruction } from './TransferInstruction';
import {
  TransferInstructionFromJSON,
  TransferInstructionFromJSONTyped,
  TransferInstructionToJSON,
  TransferInstructionToJSONTyped,
} from './TransferInstruction';
import type { CryptoTransferStatus } from './CryptoTransferStatus';
import {
  CryptoTransferStatusFromJSON,
  CryptoTransferStatusFromJSONTyped,
  CryptoTransferStatusToJSON,
  CryptoTransferStatusToJSONTyped,
} from './CryptoTransferStatus';

/**
 *
 * @export
 * @interface CryptoTransfer
 */
export interface CryptoTransfer {
  /**
   * crypto transfer identifier
   * @type {string}
   * @memberof CryptoTransfer
   */
  id: string;
  /**
   *
   * @type {CryptoTransferStatus}
   * @memberof CryptoTransfer
   */
  status: CryptoTransferStatus;
  /**
   *
   * @type {TransferInstruction}
   * @memberof CryptoTransfer
   */
  transferInstruction?: TransferInstruction;
}

/**
 * Check if a given object implements the CryptoTransfer interface.
 */
export function instanceOfCryptoTransfer(value: object): value is CryptoTransfer {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function CryptoTransferFromJSON(json: any): CryptoTransfer {
  return CryptoTransferFromJSONTyped(json, false);
}

export function CryptoTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransfer {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    status: CryptoTransferStatusFromJSON(json['status']),
    transferInstruction:
      json['transferInstruction'] == null ? undefined : TransferInstructionFromJSON(json['transferInstruction']),
  };
}

export function CryptoTransferToJSON(json: any): CryptoTransfer {
  return CryptoTransferToJSONTyped(json, false);
}

export function CryptoTransferToJSONTyped(value?: CryptoTransfer | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    status: CryptoTransferStatusToJSON(value['status']),
    transferInstruction: TransferInstructionToJSON(value['transferInstruction']),
  };
}
