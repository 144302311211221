/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateEmailVerificationRequest
 */
export interface CreateEmailVerificationRequest {
  /**
   *
   * @type {string}
   * @memberof CreateEmailVerificationRequest
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateEmailVerificationRequest
   */
  termsAndConditions: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateEmailVerificationRequest
   */
  emailOffers?: boolean;
}

/**
 * Check if a given object implements the CreateEmailVerificationRequest interface.
 */
export function instanceOfCreateEmailVerificationRequest(value: object): value is CreateEmailVerificationRequest {
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('termsAndConditions' in value) || value['termsAndConditions'] === undefined) return false;
  return true;
}

export function CreateEmailVerificationRequestFromJSON(json: any): CreateEmailVerificationRequest {
  return CreateEmailVerificationRequestFromJSONTyped(json, false);
}

export function CreateEmailVerificationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateEmailVerificationRequest {
  if (json == null) {
    return json;
  }
  return {
    email: json['email'],
    termsAndConditions: json['termsAndConditions'],
    emailOffers: json['emailOffers'] == null ? undefined : json['emailOffers'],
  };
}

export function CreateEmailVerificationRequestToJSON(json: any): CreateEmailVerificationRequest {
  return CreateEmailVerificationRequestToJSONTyped(json, false);
}

export function CreateEmailVerificationRequestToJSONTyped(
  value?: CreateEmailVerificationRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value['email'],
    termsAndConditions: value['termsAndConditions'],
    emailOffers: value['emailOffers'],
  };
}
