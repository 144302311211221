/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FiatMoney
 */
export interface FiatMoney {
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof FiatMoney
   */
  amount: string;
  /**
   * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
   * @type {string}
   * @memberof FiatMoney
   */
  currency: string;
}

/**
 * Check if a given object implements the FiatMoney interface.
 */
export function instanceOfFiatMoney(value: object): value is FiatMoney {
  if (!('amount' in value) || value['amount'] === undefined) return false;
  if (!('currency' in value) || value['currency'] === undefined) return false;
  return true;
}

export function FiatMoneyFromJSON(json: any): FiatMoney {
  return FiatMoneyFromJSONTyped(json, false);
}

export function FiatMoneyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiatMoney {
  if (json == null) {
    return json;
  }
  return {
    amount: json['amount'],
    currency: json['currency'],
  };
}

export function FiatMoneyToJSON(json: any): FiatMoney {
  return FiatMoneyToJSONTyped(json, false);
}

export function FiatMoneyToJSONTyped(value?: FiatMoney | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    amount: value['amount'],
    currency: value['currency'],
  };
}
