/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PersonalDetailsAddressesInner
 */
export interface PersonalDetailsAddressesInner {
  /**
   *
   * @type {string}
   * @memberof PersonalDetailsAddressesInner
   */
  line1?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDetailsAddressesInner
   */
  line2?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDetailsAddressesInner
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDetailsAddressesInner
   */
  zip?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDetailsAddressesInner
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDetailsAddressesInner
   */
  state?: string;
}

/**
 * Check if a given object implements the PersonalDetailsAddressesInner interface.
 */
export function instanceOfPersonalDetailsAddressesInner(value: object): value is PersonalDetailsAddressesInner {
  return true;
}

export function PersonalDetailsAddressesInnerFromJSON(json: any): PersonalDetailsAddressesInner {
  return PersonalDetailsAddressesInnerFromJSONTyped(json, false);
}

export function PersonalDetailsAddressesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PersonalDetailsAddressesInner {
  if (json == null) {
    return json;
  }
  return {
    line1: json['line1'] == null ? undefined : json['line1'],
    line2: json['line2'] == null ? undefined : json['line2'],
    city: json['city'] == null ? undefined : json['city'],
    zip: json['zip'] == null ? undefined : json['zip'],
    country: json['country'] == null ? undefined : json['country'],
    state: json['state'] == null ? undefined : json['state'],
  };
}

export function PersonalDetailsAddressesInnerToJSON(json: any): PersonalDetailsAddressesInner {
  return PersonalDetailsAddressesInnerToJSONTyped(json, false);
}

export function PersonalDetailsAddressesInnerToJSONTyped(
  value?: PersonalDetailsAddressesInner | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    line1: value['line1'],
    line2: value['line2'],
    city: value['city'],
    zip: value['zip'],
    country: value['country'],
    state: value['state'],
  };
}
