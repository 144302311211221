import { type ProblemDetails, ResponseError } from '../../client';

export async function withApiError<T, TArgs, TApi>(
  client: TApi,
  args: TArgs,
  fn: (client: TApi, args: TArgs) => Promise<T>,
) {
  try {
    return await fn(client, args);
  } catch (err) {
    const problemDetails = await parseProblemDetails(err);
    throw new ApiError(problemDetails);
  }
}

export function isApiError(err: unknown): err is ApiError {
  return err instanceof ApiError;
}

export class ApiError extends Error {
  override name = 'ApiError' as const;
  constructor(public readonly details: ProblemDetails) {
    super(details.detail ?? 'api error');
  }
}

async function parseProblemDetails(err: unknown) {
  if (!(err instanceof ResponseError)) {
    throw new Error('Expected error to be of class ResponseError');
  }
  const result = (await err.response.json()) as ProblemDetails;
  return result;
}

export function invalidParams<TKey extends string>(
  params: ProblemDetails,
  reason: (key: string) => string,
): Record<TKey, string[]> {
  return (params.invalidParams?.reduce(
    (acc, current) => {
      if (acc[current.name as TKey]) {
        acc[current.name as TKey].push(reason(current.reason));
        return acc;
      }

      acc[current.name as TKey] = [reason(current.reason)];
      return acc;
    },
    {} as Record<TKey, string[]>,
  ) ?? {}) as Record<TKey, string[]>;
}
