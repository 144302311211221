/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import {
  CryptoMoneyFromJSON,
  CryptoMoneyFromJSONTyped,
  CryptoMoneyToJSON,
  CryptoMoneyToJSONTyped,
} from './CryptoMoney';

/**
 *
 * @export
 * @interface TransactionCryptoTransferProcess
 */
export interface TransactionCryptoTransferProcess {
  /**
   * process status
   * @type {string}
   * @memberof TransactionCryptoTransferProcess
   */
  status: TransactionCryptoTransferProcessStatus;
  /**
   *
   * @type {string}
   * @memberof TransactionCryptoTransferProcess
   */
  cryptoTransferId?: string;
  /**
   *
   * @type {CryptoMoney}
   * @memberof TransactionCryptoTransferProcess
   */
  receivedCrypto?: CryptoMoney;
}

/**
 * @export
 */
export const TransactionCryptoTransferProcessStatus = {
  Pending: 'pending',
  Initiated: 'initiated',
  Completed: 'completed',
  Underpayment: 'underpayment',
} as const;
export type TransactionCryptoTransferProcessStatus =
  (typeof TransactionCryptoTransferProcessStatus)[keyof typeof TransactionCryptoTransferProcessStatus];

/**
 * Check if a given object implements the TransactionCryptoTransferProcess interface.
 */
export function instanceOfTransactionCryptoTransferProcess(value: object): value is TransactionCryptoTransferProcess {
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function TransactionCryptoTransferProcessFromJSON(json: any): TransactionCryptoTransferProcess {
  return TransactionCryptoTransferProcessFromJSONTyped(json, false);
}

export function TransactionCryptoTransferProcessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionCryptoTransferProcess {
  if (json == null) {
    return json;
  }
  return {
    status: json['status'],
    cryptoTransferId: json['cryptoTransferId'] == null ? undefined : json['cryptoTransferId'],
    receivedCrypto: json['receivedCrypto'] == null ? undefined : CryptoMoneyFromJSON(json['receivedCrypto']),
  };
}

export function TransactionCryptoTransferProcessToJSON(json: any): TransactionCryptoTransferProcess {
  return TransactionCryptoTransferProcessToJSONTyped(json, false);
}

export function TransactionCryptoTransferProcessToJSONTyped(
  value?: TransactionCryptoTransferProcess | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    status: value['status'],
    cryptoTransferId: value['cryptoTransferId'],
    receivedCrypto: CryptoMoneyToJSON(value['receivedCrypto']),
  };
}
