/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import {
  CryptoMoneyFromJSON,
  CryptoMoneyFromJSONTyped,
  CryptoMoneyToJSON,
  CryptoMoneyToJSONTyped,
} from './CryptoMoney';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON, FiatMoneyToJSONTyped } from './FiatMoney';
import type { CryptoTransferType } from './CryptoTransferType';
import {
  CryptoTransferTypeFromJSON,
  CryptoTransferTypeFromJSONTyped,
  CryptoTransferTypeToJSON,
  CryptoTransferTypeToJSONTyped,
} from './CryptoTransferType';

/**
 *
 * @export
 * @interface TransactionSummary
 */
export interface TransactionSummary {
  /**
   * Unique ID of the transaction.
   * @type {string}
   * @memberof TransactionSummary
   */
  id: string;
  /**
   * Date and time when the transaction was created.
   * @type {Date}
   * @memberof TransactionSummary
   */
  createdAt: Date;
  /**
   * Current status of the transaction.
   * @type {string}
   * @memberof TransactionSummary
   */
  status: TransactionSummaryStatus;
  /**
   * Date and time when the transaction was updated, if available.
   * @type {Date}
   * @memberof TransactionSummary
   */
  updatedAt?: Date;
  /**
   *
   * @type {CryptoTransferType}
   * @memberof TransactionSummary
   */
  cryptoTransferType?: CryptoTransferType;
  /**
   *
   * @type {string}
   * @memberof TransactionSummary
   */
  paymentMethod?: string;
  /**
   *
   * @type {CryptoMoney}
   * @memberof TransactionSummary
   */
  receivedCrypto?: CryptoMoney;
  /**
   *
   * @type {FiatMoney}
   * @memberof TransactionSummary
   */
  payoutFiat?: FiatMoney;
}

/**
 * @export
 */
export const TransactionSummaryStatus = {
  Completed: 'COMPLETED',
  FailedFiatPayout: 'FAILED_FIAT_PAYOUT',
  FailedCryptoLiquidation: 'FAILED_CRYPTO_LIQUIDATION',
  FailedCryptoLiquidationAgreementRejected: 'FAILED_CRYPTO_LIQUIDATION_AGREEMENT_REJECTED',
  FailedLpKycVerificationRejected: 'FAILED_LP_KYC_VERIFICATION_REJECTED',
  FailedKycVerificationNotApproved: 'FAILED_KYC_VERIFICATION_NOT_APPROVED',
  FailedKycVerificationApplicantRejected: 'FAILED_KYC_VERIFICATION_APPLICANT_REJECTED',
  FailedKycVerificationTimedOut: 'FAILED_KYC_VERIFICATION_TIMED_OUT',
  FailedExpired: 'FAILED_EXPIRED',
  InProgress: 'IN_PROGRESS',
} as const;
export type TransactionSummaryStatus = (typeof TransactionSummaryStatus)[keyof typeof TransactionSummaryStatus];

/**
 * Check if a given object implements the TransactionSummary interface.
 */
export function instanceOfTransactionSummary(value: object): value is TransactionSummary {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function TransactionSummaryFromJSON(json: any): TransactionSummary {
  return TransactionSummaryFromJSONTyped(json, false);
}

export function TransactionSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionSummary {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: new Date(json['created_at']),
    status: json['status'],
    updatedAt: json['updated_at'] == null ? undefined : new Date(json['updated_at']),
    cryptoTransferType:
      json['crypto_transfer_type'] == null ? undefined : CryptoTransferTypeFromJSON(json['crypto_transfer_type']),
    paymentMethod: json['payment_method'] == null ? undefined : json['payment_method'],
    receivedCrypto: json['received_crypto'] == null ? undefined : CryptoMoneyFromJSON(json['received_crypto']),
    payoutFiat: json['payout_fiat'] == null ? undefined : FiatMoneyFromJSON(json['payout_fiat']),
  };
}

export function TransactionSummaryToJSON(json: any): TransactionSummary {
  return TransactionSummaryToJSONTyped(json, false);
}

export function TransactionSummaryToJSONTyped(
  value?: TransactionSummary | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    created_at: value['createdAt'].toISOString(),
    status: value['status'],
    updated_at: value['updatedAt'] == null ? undefined : value['updatedAt'].toISOString(),
    crypto_transfer_type: CryptoTransferTypeToJSON(value['cryptoTransferType']),
    payment_method: value['paymentMethod'],
    received_crypto: CryptoMoneyToJSON(value['receivedCrypto']),
    payout_fiat: FiatMoneyToJSON(value['payoutFiat']),
  };
}
