/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoTransferType } from './CryptoTransferType';
import {
  CryptoTransferTypeFromJSON,
  CryptoTransferTypeFromJSONTyped,
  CryptoTransferTypeToJSON,
  CryptoTransferTypeToJSONTyped,
} from './CryptoTransferType';

/**
 *
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
  /**
   * URL-like value
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  returnUrl?: string;
  /**
   * Opaque entity identifier.
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  quoteId: string;
  /**
   *
   * @type {CryptoTransferType}
   * @memberof CreateTransactionRequest
   */
  cryptoTransferType: CryptoTransferType;
}

/**
 * Check if a given object implements the CreateTransactionRequest interface.
 */
export function instanceOfCreateTransactionRequest(value: object): value is CreateTransactionRequest {
  if (!('quoteId' in value) || value['quoteId'] === undefined) return false;
  if (!('cryptoTransferType' in value) || value['cryptoTransferType'] === undefined) return false;
  return true;
}

export function CreateTransactionRequestFromJSON(json: any): CreateTransactionRequest {
  return CreateTransactionRequestFromJSONTyped(json, false);
}

export function CreateTransactionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateTransactionRequest {
  if (json == null) {
    return json;
  }
  return {
    returnUrl: json['return_url'] == null ? undefined : json['return_url'],
    quoteId: json['quote_id'],
    cryptoTransferType: CryptoTransferTypeFromJSON(json['crypto_transfer_type']),
  };
}

export function CreateTransactionRequestToJSON(json: any): CreateTransactionRequest {
  return CreateTransactionRequestToJSONTyped(json, false);
}

export function CreateTransactionRequestToJSONTyped(
  value?: CreateTransactionRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    return_url: value['returnUrl'],
    quote_id: value['quoteId'],
    crypto_transfer_type: CryptoTransferTypeToJSON(value['cryptoTransferType']),
  };
}
