/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateEmailVerificationAttemptRequest
 */
export interface CreateEmailVerificationAttemptRequest {
  /**
   *
   * @type {string}
   * @memberof CreateEmailVerificationAttemptRequest
   */
  previousAttemptId?: string;
}

/**
 * Check if a given object implements the CreateEmailVerificationAttemptRequest interface.
 */
export function instanceOfCreateEmailVerificationAttemptRequest(
  value: object,
): value is CreateEmailVerificationAttemptRequest {
  return true;
}

export function CreateEmailVerificationAttemptRequestFromJSON(json: any): CreateEmailVerificationAttemptRequest {
  return CreateEmailVerificationAttemptRequestFromJSONTyped(json, false);
}

export function CreateEmailVerificationAttemptRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateEmailVerificationAttemptRequest {
  if (json == null) {
    return json;
  }
  return {
    previousAttemptId: json['previousAttemptId'] == null ? undefined : json['previousAttemptId'],
  };
}

export function CreateEmailVerificationAttemptRequestToJSON(json: any): CreateEmailVerificationAttemptRequest {
  return CreateEmailVerificationAttemptRequestToJSONTyped(json, false);
}

export function CreateEmailVerificationAttemptRequestToJSONTyped(
  value?: CreateEmailVerificationAttemptRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    previousAttemptId: value['previousAttemptId'],
  };
}
