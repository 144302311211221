/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TransactionPaymentInstrumentProcess
 */
export interface TransactionPaymentInstrumentProcess {
  /**
   * process status
   * @type {string}
   * @memberof TransactionPaymentInstrumentProcess
   */
  status: TransactionPaymentInstrumentProcessStatus;
  /**
   *
   * @type {string}
   * @memberof TransactionPaymentInstrumentProcess
   */
  paymentInstrumentId?: string;
}

/**
 * @export
 */
export const TransactionPaymentInstrumentProcessStatus = {
  Created: 'created',
  Completed: 'completed',
} as const;
export type TransactionPaymentInstrumentProcessStatus =
  (typeof TransactionPaymentInstrumentProcessStatus)[keyof typeof TransactionPaymentInstrumentProcessStatus];

/**
 * Check if a given object implements the TransactionPaymentInstrumentProcess interface.
 */
export function instanceOfTransactionPaymentInstrumentProcess(
  value: object,
): value is TransactionPaymentInstrumentProcess {
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function TransactionPaymentInstrumentProcessFromJSON(json: any): TransactionPaymentInstrumentProcess {
  return TransactionPaymentInstrumentProcessFromJSONTyped(json, false);
}

export function TransactionPaymentInstrumentProcessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionPaymentInstrumentProcess {
  if (json == null) {
    return json;
  }
  return {
    status: json['status'],
    paymentInstrumentId: json['paymentInstrumentId'] == null ? undefined : json['paymentInstrumentId'],
  };
}

export function TransactionPaymentInstrumentProcessToJSON(json: any): TransactionPaymentInstrumentProcess {
  return TransactionPaymentInstrumentProcessToJSONTyped(json, false);
}

export function TransactionPaymentInstrumentProcessToJSONTyped(
  value?: TransactionPaymentInstrumentProcess | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    status: value['status'],
    paymentInstrumentId: value['paymentInstrumentId'],
  };
}
