/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountDetailsWebSessionsInner } from './AccountDetailsWebSessionsInner';
import {
  AccountDetailsWebSessionsInnerFromJSON,
  AccountDetailsWebSessionsInnerFromJSONTyped,
  AccountDetailsWebSessionsInnerToJSON,
  AccountDetailsWebSessionsInnerToJSONTyped,
} from './AccountDetailsWebSessionsInner';
import type { AccountDetailsPersonalDetails } from './AccountDetailsPersonalDetails';
import {
  AccountDetailsPersonalDetailsFromJSON,
  AccountDetailsPersonalDetailsFromJSONTyped,
  AccountDetailsPersonalDetailsToJSON,
  AccountDetailsPersonalDetailsToJSONTyped,
} from './AccountDetailsPersonalDetails';

/**
 *
 * @export
 * @interface InitiateSellAcountDetails
 */
export interface InitiateSellAcountDetails {
  /**
   * The identifier you use for the end-user’s account.
   * @type {string}
   * @memberof InitiateSellAcountDetails
   */
  accountId: string;
  /**
   * A list of sessions/logins in the account, each with at least an IP and a timestamp.
   * @type {Array<AccountDetailsWebSessionsInner>}
   * @memberof InitiateSellAcountDetails
   */
  webSessions?: Array<AccountDetailsWebSessionsInner>;
  /**
   *
   * @type {AccountDetailsPersonalDetails}
   * @memberof InitiateSellAcountDetails
   */
  personalDetails?: AccountDetailsPersonalDetails;
}

/**
 * Check if a given object implements the InitiateSellAcountDetails interface.
 */
export function instanceOfInitiateSellAcountDetails(value: object): value is InitiateSellAcountDetails {
  if (!('accountId' in value) || value['accountId'] === undefined) return false;
  return true;
}

export function InitiateSellAcountDetailsFromJSON(json: any): InitiateSellAcountDetails {
  return InitiateSellAcountDetailsFromJSONTyped(json, false);
}

export function InitiateSellAcountDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InitiateSellAcountDetails {
  if (json == null) {
    return json;
  }
  return {
    accountId: json['account_id'],
    webSessions:
      json['web_sessions'] == null
        ? undefined
        : (json['web_sessions'] as Array<any>).map(AccountDetailsWebSessionsInnerFromJSON),
    personalDetails:
      json['personal_details'] == null ? undefined : AccountDetailsPersonalDetailsFromJSON(json['personal_details']),
  };
}

export function InitiateSellAcountDetailsToJSON(json: any): InitiateSellAcountDetails {
  return InitiateSellAcountDetailsToJSONTyped(json, false);
}

export function InitiateSellAcountDetailsToJSONTyped(
  value?: InitiateSellAcountDetails | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    account_id: value['accountId'],
    web_sessions:
      value['webSessions'] == null
        ? undefined
        : (value['webSessions'] as Array<any>).map(AccountDetailsWebSessionsInnerToJSON),
    personal_details: AccountDetailsPersonalDetailsToJSON(value['personalDetails']),
  };
}
