/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransferInstructionStatus } from './TransferInstructionStatus';
import {
  TransferInstructionStatusFromJSON,
  TransferInstructionStatusFromJSONTyped,
  TransferInstructionStatusToJSON,
  TransferInstructionStatusToJSONTyped,
} from './TransferInstructionStatus';
import type { TransactionCryptoTransferInstructions } from './TransactionCryptoTransferInstructions';
import {
  TransactionCryptoTransferInstructionsFromJSON,
  TransactionCryptoTransferInstructionsFromJSONTyped,
  TransactionCryptoTransferInstructionsToJSON,
  TransactionCryptoTransferInstructionsToJSONTyped,
} from './TransactionCryptoTransferInstructions';

/**
 *
 * @export
 * @interface TransactionCryptoTransferInstructionsResponse
 */
export interface TransactionCryptoTransferInstructionsResponse {
  /**
   *
   * @type {TransferInstructionStatus}
   * @memberof TransactionCryptoTransferInstructionsResponse
   */
  status: TransferInstructionStatus;
  /**
   *
   * @type {TransactionCryptoTransferInstructions}
   * @memberof TransactionCryptoTransferInstructionsResponse
   */
  transferInstructions?: TransactionCryptoTransferInstructions;
}

/**
 * Check if a given object implements the TransactionCryptoTransferInstructionsResponse interface.
 */
export function instanceOfTransactionCryptoTransferInstructionsResponse(
  value: object,
): value is TransactionCryptoTransferInstructionsResponse {
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function TransactionCryptoTransferInstructionsResponseFromJSON(
  json: any,
): TransactionCryptoTransferInstructionsResponse {
  return TransactionCryptoTransferInstructionsResponseFromJSONTyped(json, false);
}

export function TransactionCryptoTransferInstructionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionCryptoTransferInstructionsResponse {
  if (json == null) {
    return json;
  }
  return {
    status: TransferInstructionStatusFromJSON(json['status']),
    transferInstructions:
      json['transfer_instructions'] == null
        ? undefined
        : TransactionCryptoTransferInstructionsFromJSON(json['transfer_instructions']),
  };
}

export function TransactionCryptoTransferInstructionsResponseToJSON(
  json: any,
): TransactionCryptoTransferInstructionsResponse {
  return TransactionCryptoTransferInstructionsResponseToJSONTyped(json, false);
}

export function TransactionCryptoTransferInstructionsResponseToJSONTyped(
  value?: TransactionCryptoTransferInstructionsResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    status: TransferInstructionStatusToJSON(value['status']),
    transfer_instructions: TransactionCryptoTransferInstructionsToJSON(value['transferInstructions']),
  };
}
