/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionOffer } from './TransactionOffer';
import {
  TransactionOfferFromJSON,
  TransactionOfferFromJSONTyped,
  TransactionOfferToJSON,
  TransactionOfferToJSONTyped,
} from './TransactionOffer';

/**
 *
 * @export
 * @interface TransactionOffers
 */
export interface TransactionOffers {
  /**
   *
   * @type {Array<TransactionOffer>}
   * @memberof TransactionOffers
   */
  offers: Array<TransactionOffer>;
  /**
   *
   * @type {boolean}
   * @memberof TransactionOffers
   */
  restricted: boolean;
}

/**
 * Check if a given object implements the TransactionOffers interface.
 */
export function instanceOfTransactionOffers(value: object): value is TransactionOffers {
  if (!('offers' in value) || value['offers'] === undefined) return false;
  if (!('restricted' in value) || value['restricted'] === undefined) return false;
  return true;
}

export function TransactionOffersFromJSON(json: any): TransactionOffers {
  return TransactionOffersFromJSONTyped(json, false);
}

export function TransactionOffersFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionOffers {
  if (json == null) {
    return json;
  }
  return {
    offers: (json['offers'] as Array<any>).map(TransactionOfferFromJSON),
    restricted: json['restricted'],
  };
}

export function TransactionOffersToJSON(json: any): TransactionOffers {
  return TransactionOffersToJSONTyped(json, false);
}

export function TransactionOffersToJSONTyped(
  value?: TransactionOffers | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    offers: (value['offers'] as Array<any>).map(TransactionOfferToJSON),
    restricted: value['restricted'],
  };
}
