/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Request to create SEPA payment instrument
 * @export
 * @interface CreateSepaPaymentInstrumentRequest
 */
export interface CreateSepaPaymentInstrumentRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSepaPaymentInstrumentRequest
   */
  transactionId: string;
  /**
   *
   * @type {string}
   * @memberof CreateSepaPaymentInstrumentRequest
   */
  iban: string;
  /**
   *
   * @type {string}
   * @memberof CreateSepaPaymentInstrumentRequest
   */
  bankName: string;
  /**
   *
   * @type {string}
   * @memberof CreateSepaPaymentInstrumentRequest
   */
  bankBic: string;
}

/**
 * Check if a given object implements the CreateSepaPaymentInstrumentRequest interface.
 */
export function instanceOfCreateSepaPaymentInstrumentRequest(
  value: object,
): value is CreateSepaPaymentInstrumentRequest {
  if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
  if (!('iban' in value) || value['iban'] === undefined) return false;
  if (!('bankName' in value) || value['bankName'] === undefined) return false;
  if (!('bankBic' in value) || value['bankBic'] === undefined) return false;
  return true;
}

export function CreateSepaPaymentInstrumentRequestFromJSON(json: any): CreateSepaPaymentInstrumentRequest {
  return CreateSepaPaymentInstrumentRequestFromJSONTyped(json, false);
}

export function CreateSepaPaymentInstrumentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateSepaPaymentInstrumentRequest {
  if (json == null) {
    return json;
  }
  return {
    transactionId: json['transactionId'],
    iban: json['iban'],
    bankName: json['bankName'],
    bankBic: json['bankBic'],
  };
}

export function CreateSepaPaymentInstrumentRequestToJSON(json: any): CreateSepaPaymentInstrumentRequest {
  return CreateSepaPaymentInstrumentRequestToJSONTyped(json, false);
}

export function CreateSepaPaymentInstrumentRequestToJSONTyped(
  value?: CreateSepaPaymentInstrumentRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    transactionId: value['transactionId'],
    iban: value['iban'],
    bankName: value['bankName'],
    bankBic: value['bankBic'],
  };
}
