/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrafficProviderQuote
 */
export interface TrafficProviderQuote {
  /**
   * Opaque entity identifier.
   * @type {string}
   * @memberof TrafficProviderQuote
   */
  quoteId: string;
  /**
   * A floating-point number representing the number of seconds since the Epoch (Jan 1, 1970 at 0:00am). Millisecond resolution.
   * E.g. `1537078623.305` represents `Sep 16, 2018 at 06:17:03.305 UTC`.
   * @type {number}
   * @memberof TrafficProviderQuote
   */
  expiryTs: number;
  /**
   * The exchange rate: how many units of `quote_currency` equal one unit of `base_currency`.
   * The rate you provide may take the amount into consideration: Simplex will use this rate for this particular quote only, and will not assume that the rate holds for different amounts.
   * @type {number}
   * @memberof TrafficProviderQuote
   */
  rate: number;
  /**
   * The amount of `quote_currency` that the user will receive.
   * @type {string}
   * @memberof TrafficProviderQuote
   */
  fiatAmount: string;
}

/**
 * Check if a given object implements the TrafficProviderQuote interface.
 */
export function instanceOfTrafficProviderQuote(value: object): value is TrafficProviderQuote {
  if (!('quoteId' in value) || value['quoteId'] === undefined) return false;
  if (!('expiryTs' in value) || value['expiryTs'] === undefined) return false;
  if (!('rate' in value) || value['rate'] === undefined) return false;
  if (!('fiatAmount' in value) || value['fiatAmount'] === undefined) return false;
  return true;
}

export function TrafficProviderQuoteFromJSON(json: any): TrafficProviderQuote {
  return TrafficProviderQuoteFromJSONTyped(json, false);
}

export function TrafficProviderQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficProviderQuote {
  if (json == null) {
    return json;
  }
  return {
    quoteId: json['quote_id'],
    expiryTs: json['expiry_ts'],
    rate: json['rate'],
    fiatAmount: json['fiat_amount'],
  };
}

export function TrafficProviderQuoteToJSON(json: any): TrafficProviderQuote {
  return TrafficProviderQuoteToJSONTyped(json, false);
}

export function TrafficProviderQuoteToJSONTyped(
  value?: TrafficProviderQuote | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    quote_id: value['quoteId'],
    expiry_ts: value['expiryTs'],
    rate: value['rate'],
    fiat_amount: value['fiatAmount'],
  };
}
