/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionLiquidityProvider } from './TransactionLiquidityProvider';
import {
  TransactionLiquidityProviderFromJSON,
  TransactionLiquidityProviderFromJSONTyped,
  TransactionLiquidityProviderToJSON,
  TransactionLiquidityProviderToJSONTyped,
} from './TransactionLiquidityProvider';

/**
 *
 * @export
 * @interface TransactionOrderResponse
 */
export interface TransactionOrderResponse {
  /**
   *
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  id: string;
  /**
   * Crypto currency with optional network identifier attached.
   * List of crypto currencies Simplex might send you:
   * - USDT
   * - TRX
   * - BTC
   * - USDP
   * - AAVE
   * - USDC
   * - UNI
   * - COTI-ERC20
   * - TUSD
   * - COTI
   * - BUSD
   * - HTR
   * - USDT-TRC20
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  cryptoCurrency: string;
  /**
   * Crypto icon URL
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  cryptoCurrencyLogoUrl: string;
  /**
   * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  fiatCurrency: string;
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  totalAmount: string;
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  totalAmountWithoutFees: string;
  /**
   * crypto amount. In major units. I.e. 3.4565432344
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  cryptoAmount: string;
  /**
   *
   * @type {number}
   * @memberof TransactionOrderResponse
   */
  cryptoDecimals: number;
  /**
   * Crypto exchange rate
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  exchangeRate: string;
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  processingFee: string;
  /**
   *
   * @type {TransactionLiquidityProvider}
   * @memberof TransactionOrderResponse
   */
  liquidityProvider: TransactionLiquidityProvider;
  /**
   *
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  transactionId: string;
  /**
   *
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  paymentMethod: string;
  /**
   *
   * @type {string}
   * @memberof TransactionOrderResponse
   */
  createdAt: string;
}

/**
 * Check if a given object implements the TransactionOrderResponse interface.
 */
export function instanceOfTransactionOrderResponse(value: object): value is TransactionOrderResponse {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('cryptoCurrency' in value) || value['cryptoCurrency'] === undefined) return false;
  if (!('cryptoCurrencyLogoUrl' in value) || value['cryptoCurrencyLogoUrl'] === undefined) return false;
  if (!('fiatCurrency' in value) || value['fiatCurrency'] === undefined) return false;
  if (!('totalAmount' in value) || value['totalAmount'] === undefined) return false;
  if (!('totalAmountWithoutFees' in value) || value['totalAmountWithoutFees'] === undefined) return false;
  if (!('cryptoAmount' in value) || value['cryptoAmount'] === undefined) return false;
  if (!('cryptoDecimals' in value) || value['cryptoDecimals'] === undefined) return false;
  if (!('exchangeRate' in value) || value['exchangeRate'] === undefined) return false;
  if (!('processingFee' in value) || value['processingFee'] === undefined) return false;
  if (!('liquidityProvider' in value) || value['liquidityProvider'] === undefined) return false;
  if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
  if (!('paymentMethod' in value) || value['paymentMethod'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  return true;
}

export function TransactionOrderResponseFromJSON(json: any): TransactionOrderResponse {
  return TransactionOrderResponseFromJSONTyped(json, false);
}

export function TransactionOrderResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionOrderResponse {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    cryptoCurrency: json['cryptoCurrency'],
    cryptoCurrencyLogoUrl: json['cryptoCurrencyLogoUrl'],
    fiatCurrency: json['fiatCurrency'],
    totalAmount: json['totalAmount'],
    totalAmountWithoutFees: json['totalAmountWithoutFees'],
    cryptoAmount: json['cryptoAmount'],
    cryptoDecimals: json['cryptoDecimals'],
    exchangeRate: json['exchangeRate'],
    processingFee: json['processingFee'],
    liquidityProvider: TransactionLiquidityProviderFromJSON(json['liquidityProvider']),
    transactionId: json['transactionId'],
    paymentMethod: json['paymentMethod'],
    createdAt: json['createdAt'],
  };
}

export function TransactionOrderResponseToJSON(json: any): TransactionOrderResponse {
  return TransactionOrderResponseToJSONTyped(json, false);
}

export function TransactionOrderResponseToJSONTyped(
  value?: TransactionOrderResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    cryptoCurrency: value['cryptoCurrency'],
    cryptoCurrencyLogoUrl: value['cryptoCurrencyLogoUrl'],
    fiatCurrency: value['fiatCurrency'],
    totalAmount: value['totalAmount'],
    totalAmountWithoutFees: value['totalAmountWithoutFees'],
    cryptoAmount: value['cryptoAmount'],
    cryptoDecimals: value['cryptoDecimals'],
    exchangeRate: value['exchangeRate'],
    processingFee: value['processingFee'],
    liquidityProvider: TransactionLiquidityProviderToJSON(value['liquidityProvider']),
    transactionId: value['transactionId'],
    paymentMethod: value['paymentMethod'],
    createdAt: value['createdAt'],
  };
}
