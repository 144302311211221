/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionPaymentInstrumentProcess } from './TransactionPaymentInstrumentProcess';
import {
  TransactionPaymentInstrumentProcessFromJSON,
  TransactionPaymentInstrumentProcessFromJSONTyped,
  TransactionPaymentInstrumentProcessToJSON,
  TransactionPaymentInstrumentProcessToJSONTyped,
} from './TransactionPaymentInstrumentProcess';
import type { TransactionPaymentInstrumentRecollectionVerificationProcess } from './TransactionPaymentInstrumentRecollectionVerificationProcess';
import {
  TransactionPaymentInstrumentRecollectionVerificationProcessFromJSON,
  TransactionPaymentInstrumentRecollectionVerificationProcessFromJSONTyped,
  TransactionPaymentInstrumentRecollectionVerificationProcessToJSON,
  TransactionPaymentInstrumentRecollectionVerificationProcessToJSONTyped,
} from './TransactionPaymentInstrumentRecollectionVerificationProcess';
import type { TransactionTrafficProvider } from './TransactionTrafficProvider';
import {
  TransactionTrafficProviderFromJSON,
  TransactionTrafficProviderFromJSONTyped,
  TransactionTrafficProviderToJSON,
  TransactionTrafficProviderToJSONTyped,
} from './TransactionTrafficProvider';
import type { TransactionOrder } from './TransactionOrder';
import {
  TransactionOrderFromJSON,
  TransactionOrderFromJSONTyped,
  TransactionOrderToJSON,
  TransactionOrderToJSONTyped,
} from './TransactionOrder';
import type { TransactionCryptoLiquidationProcess } from './TransactionCryptoLiquidationProcess';
import {
  TransactionCryptoLiquidationProcessFromJSON,
  TransactionCryptoLiquidationProcessFromJSONTyped,
  TransactionCryptoLiquidationProcessToJSON,
  TransactionCryptoLiquidationProcessToJSONTyped,
} from './TransactionCryptoLiquidationProcess';
import type { TransactionPaymentInstrumentRecollectionProcess } from './TransactionPaymentInstrumentRecollectionProcess';
import {
  TransactionPaymentInstrumentRecollectionProcessFromJSON,
  TransactionPaymentInstrumentRecollectionProcessFromJSONTyped,
  TransactionPaymentInstrumentRecollectionProcessToJSON,
  TransactionPaymentInstrumentRecollectionProcessToJSONTyped,
} from './TransactionPaymentInstrumentRecollectionProcess';
import type { TransactionKycCollection } from './TransactionKycCollection';
import {
  TransactionKycCollectionFromJSON,
  TransactionKycCollectionFromJSONTyped,
  TransactionKycCollectionToJSON,
  TransactionKycCollectionToJSONTyped,
} from './TransactionKycCollection';
import type { TransactionLpKycVerification } from './TransactionLpKycVerification';
import {
  TransactionLpKycVerificationFromJSON,
  TransactionLpKycVerificationFromJSONTyped,
  TransactionLpKycVerificationToJSON,
  TransactionLpKycVerificationToJSONTyped,
} from './TransactionLpKycVerification';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON, FiatMoneyToJSONTyped } from './FiatMoney';
import type { TransactionApplicant } from './TransactionApplicant';
import {
  TransactionApplicantFromJSON,
  TransactionApplicantFromJSONTyped,
  TransactionApplicantToJSON,
  TransactionApplicantToJSONTyped,
} from './TransactionApplicant';
import type { TransactionFiatPayoutProcess } from './TransactionFiatPayoutProcess';
import {
  TransactionFiatPayoutProcessFromJSON,
  TransactionFiatPayoutProcessFromJSONTyped,
  TransactionFiatPayoutProcessToJSON,
  TransactionFiatPayoutProcessToJSONTyped,
} from './TransactionFiatPayoutProcess';
import type { CryptoTransferType } from './CryptoTransferType';
import {
  CryptoTransferTypeFromJSON,
  CryptoTransferTypeFromJSONTyped,
  CryptoTransferTypeToJSON,
  CryptoTransferTypeToJSONTyped,
} from './CryptoTransferType';
import type { TransactionKycVerification } from './TransactionKycVerification';
import {
  TransactionKycVerificationFromJSON,
  TransactionKycVerificationFromJSONTyped,
  TransactionKycVerificationToJSON,
  TransactionKycVerificationToJSONTyped,
} from './TransactionKycVerification';
import type { TransactionCryptoTransferProcess } from './TransactionCryptoTransferProcess';
import {
  TransactionCryptoTransferProcessFromJSON,
  TransactionCryptoTransferProcessFromJSONTyped,
  TransactionCryptoTransferProcessToJSON,
  TransactionCryptoTransferProcessToJSONTyped,
} from './TransactionCryptoTransferProcess';

/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
  /**
   * transaction identifier
   * @type {string}
   * @memberof Transaction
   */
  id: string;
  /**
   *
   * @type {TransactionTrafficProvider}
   * @memberof Transaction
   */
  trafficProvider: TransactionTrafficProvider;
  /**
   *
   * @type {TransactionApplicant}
   * @memberof Transaction
   */
  applicant?: TransactionApplicant;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  flowType: TransactionFlowType;
  /**
   *
   * @type {CryptoTransferType}
   * @memberof Transaction
   */
  cryptoTransferType?: CryptoTransferType;
  /**
   *
   * @type {TransactionKycVerification}
   * @memberof Transaction
   */
  kycVerification?: TransactionKycVerification;
  /**
   *
   * @type {TransactionKycCollection}
   * @memberof Transaction
   */
  kycCollection?: TransactionKycCollection;
  /**
   *
   * @type {TransactionLpKycVerification}
   * @memberof Transaction
   */
  lpKycVerification?: TransactionLpKycVerification;
  /**
   *
   * @type {TransactionCryptoTransferProcess}
   * @memberof Transaction
   */
  cryptoTransferProcess?: TransactionCryptoTransferProcess;
  /**
   *
   * @type {TransactionCryptoLiquidationProcess}
   * @memberof Transaction
   */
  cryptoLiquidationProcess?: TransactionCryptoLiquidationProcess;
  /**
   *
   * @type {TransactionFiatPayoutProcess}
   * @memberof Transaction
   */
  fiatPayoutProcess?: TransactionFiatPayoutProcess;
  /**
   *
   * @type {TransactionPaymentInstrumentRecollectionProcess}
   * @memberof Transaction
   */
  paymentInstrumentRecollectionProcess?: TransactionPaymentInstrumentRecollectionProcess;
  /**
   *
   * @type {TransactionPaymentInstrumentRecollectionVerificationProcess}
   * @memberof Transaction
   */
  paymentInstrumentRecollectionVerificationProcess?: TransactionPaymentInstrumentRecollectionVerificationProcess;
  /**
   *
   * @type {TransactionPaymentInstrumentProcess}
   * @memberof Transaction
   */
  paymentInstrumentProcess?: TransactionPaymentInstrumentProcess;
  /**
   *
   * @type {TransactionOrder}
   * @memberof Transaction
   */
  order?: TransactionOrder;
  /**
   *
   * @type {FiatMoney}
   * @memberof Transaction
   */
  liquidationQuote?: FiatMoney;
  /**
   *
   * @type {boolean}
   * @memberof Transaction
   */
  expired?: boolean;
}

/**
 * @export
 */
export const TransactionFlowType = {
  Wallet: 'wallet',
  Widget: 'widget',
} as const;
export type TransactionFlowType = (typeof TransactionFlowType)[keyof typeof TransactionFlowType];

/**
 * Check if a given object implements the Transaction interface.
 */
export function instanceOfTransaction(value: object): value is Transaction {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('trafficProvider' in value) || value['trafficProvider'] === undefined) return false;
  if (!('flowType' in value) || value['flowType'] === undefined) return false;
  return true;
}

export function TransactionFromJSON(json: any): Transaction {
  return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    trafficProvider: TransactionTrafficProviderFromJSON(json['trafficProvider']),
    applicant: json['applicant'] == null ? undefined : TransactionApplicantFromJSON(json['applicant']),
    flowType: json['flowType'],
    cryptoTransferType:
      json['cryptoTransferType'] == null ? undefined : CryptoTransferTypeFromJSON(json['cryptoTransferType']),
    kycVerification:
      json['kycVerification'] == null ? undefined : TransactionKycVerificationFromJSON(json['kycVerification']),
    kycCollection: json['kycCollection'] == null ? undefined : TransactionKycCollectionFromJSON(json['kycCollection']),
    lpKycVerification:
      json['lpKycVerification'] == null ? undefined : TransactionLpKycVerificationFromJSON(json['lpKycVerification']),
    cryptoTransferProcess:
      json['cryptoTransferProcess'] == null
        ? undefined
        : TransactionCryptoTransferProcessFromJSON(json['cryptoTransferProcess']),
    cryptoLiquidationProcess:
      json['cryptoLiquidationProcess'] == null
        ? undefined
        : TransactionCryptoLiquidationProcessFromJSON(json['cryptoLiquidationProcess']),
    fiatPayoutProcess:
      json['fiatPayoutProcess'] == null ? undefined : TransactionFiatPayoutProcessFromJSON(json['fiatPayoutProcess']),
    paymentInstrumentRecollectionProcess:
      json['paymentInstrumentRecollectionProcess'] == null
        ? undefined
        : TransactionPaymentInstrumentRecollectionProcessFromJSON(json['paymentInstrumentRecollectionProcess']),
    paymentInstrumentRecollectionVerificationProcess:
      json['paymentInstrumentRecollectionVerificationProcess'] == null
        ? undefined
        : TransactionPaymentInstrumentRecollectionVerificationProcessFromJSON(
            json['paymentInstrumentRecollectionVerificationProcess'],
          ),
    paymentInstrumentProcess:
      json['paymentInstrumentProcess'] == null
        ? undefined
        : TransactionPaymentInstrumentProcessFromJSON(json['paymentInstrumentProcess']),
    order: json['order'] == null ? undefined : TransactionOrderFromJSON(json['order']),
    liquidationQuote: json['liquidationQuote'] == null ? undefined : FiatMoneyFromJSON(json['liquidationQuote']),
    expired: json['expired'] == null ? undefined : json['expired'],
  };
}

export function TransactionToJSON(json: any): Transaction {
  return TransactionToJSONTyped(json, false);
}

export function TransactionToJSONTyped(value?: Transaction | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    trafficProvider: TransactionTrafficProviderToJSON(value['trafficProvider']),
    applicant: TransactionApplicantToJSON(value['applicant']),
    flowType: value['flowType'],
    cryptoTransferType: CryptoTransferTypeToJSON(value['cryptoTransferType']),
    kycVerification: TransactionKycVerificationToJSON(value['kycVerification']),
    kycCollection: TransactionKycCollectionToJSON(value['kycCollection']),
    lpKycVerification: TransactionLpKycVerificationToJSON(value['lpKycVerification']),
    cryptoTransferProcess: TransactionCryptoTransferProcessToJSON(value['cryptoTransferProcess']),
    cryptoLiquidationProcess: TransactionCryptoLiquidationProcessToJSON(value['cryptoLiquidationProcess']),
    fiatPayoutProcess: TransactionFiatPayoutProcessToJSON(value['fiatPayoutProcess']),
    paymentInstrumentRecollectionProcess: TransactionPaymentInstrumentRecollectionProcessToJSON(
      value['paymentInstrumentRecollectionProcess'],
    ),
    paymentInstrumentRecollectionVerificationProcess: TransactionPaymentInstrumentRecollectionVerificationProcessToJSON(
      value['paymentInstrumentRecollectionVerificationProcess'],
    ),
    paymentInstrumentProcess: TransactionPaymentInstrumentProcessToJSON(value['paymentInstrumentProcess']),
    order: TransactionOrderToJSON(value['order']),
    liquidationQuote: FiatMoneyToJSON(value['liquidationQuote']),
    expired: value['expired'],
  };
}
