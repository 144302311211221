/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ConfirmEmailVerificationAttemptRequest
 */
export interface ConfirmEmailVerificationAttemptRequest {
  /**
   *
   * @type {string}
   * @memberof ConfirmEmailVerificationAttemptRequest
   */
  code: string;
}

/**
 * Check if a given object implements the ConfirmEmailVerificationAttemptRequest interface.
 */
export function instanceOfConfirmEmailVerificationAttemptRequest(
  value: object,
): value is ConfirmEmailVerificationAttemptRequest {
  if (!('code' in value) || value['code'] === undefined) return false;
  return true;
}

export function ConfirmEmailVerificationAttemptRequestFromJSON(json: any): ConfirmEmailVerificationAttemptRequest {
  return ConfirmEmailVerificationAttemptRequestFromJSONTyped(json, false);
}

export function ConfirmEmailVerificationAttemptRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfirmEmailVerificationAttemptRequest {
  if (json == null) {
    return json;
  }
  return {
    code: json['code'],
  };
}

export function ConfirmEmailVerificationAttemptRequestToJSON(json: any): ConfirmEmailVerificationAttemptRequest {
  return ConfirmEmailVerificationAttemptRequestToJSONTyped(json, false);
}

export function ConfirmEmailVerificationAttemptRequestToJSONTyped(
  value?: ConfirmEmailVerificationAttemptRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    code: value['code'],
  };
}
