import type { Currencies, CurrenciesCryptoCurrenciesInner } from '../client';

export type CurrenciesConfig = ReturnType<typeof mapToCurrenciesConfigs>;

export function createCurrenciesPlaceholder() {
  return {
    fiatCurrencies: [
      {
        label: 'Euro (EUR)',
        value: 'EUR',
        imageSrc: 'https://s3.amazonaws.com/affiliate-sdk-production-configuration/assets/icons/eur.svg',
        selectedLabel: 'EUR',
      },
    ],
    cryptoCurrencies: [
      {
        currencySelectOption: {
          label: 'Bitcoin (BTC)',
          value: 'BTC',
          imageSrc: 'https://s3.amazonaws.com/affiliate-sdk-production-configuration/assets/icons/btc.svg',
          selectedLabel: 'BTC',
        },
      },
    ],
  };
}

export function mapToCurrenciesConfigs(currencies: Currencies) {
  return {
    fiatCurrencies: currencies.fiatCurrencies.map((c) => ({
      label: c.name,
      value: c.code,
      imageSrc: c.imageUrl,
      selectedLabel: c.code,
      minAmount: c.minAmount,
      maxAmount: c.maxAmount,
    })),
    cryptoCurrencies: currencies.cryptoCurrencies.map((c) => ({
      currencySelectOption: {
        label: toLabel(c),
        value: c.id,
        imageSrc: c.imageUrl,
        selectedLabel: c.id,
        defaultAmount: c.defaultAmount,
      },
      rounding: c.decimals ? getAmountRounding(c.decimals) : undefined,
    })),
  };

  function toLabel(c: CurrenciesCryptoCurrenciesInner) {
    return `${c.name} (${c.id})`;
  }
}

function getAmountRounding(roundingPoints: number) {
  // eslint-disable-next-line security/detect-non-literal-regexp
  return new RegExp(`^[1-9]\\d*(\\.\\d{1,${roundingPoints}})?$|^0(\\.\\d{1,${roundingPoints}})?$`);
}
