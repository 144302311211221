/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface IbanDetails
 */
export interface IbanDetails {
  /**
   *
   * @type {string}
   * @memberof IbanDetails
   */
  iban: string;
  /**
   *
   * @type {string}
   * @memberof IbanDetails
   */
  bankName: string;
  /**
   * BIC code of the issuing bank
   * @type {string}
   * @memberof IbanDetails
   */
  bankBic: string;
}

/**
 * Check if a given object implements the IbanDetails interface.
 */
export function instanceOfIbanDetails(value: object): value is IbanDetails {
  if (!('iban' in value) || value['iban'] === undefined) return false;
  if (!('bankName' in value) || value['bankName'] === undefined) return false;
  if (!('bankBic' in value) || value['bankBic'] === undefined) return false;
  return true;
}

export function IbanDetailsFromJSON(json: any): IbanDetails {
  return IbanDetailsFromJSONTyped(json, false);
}

export function IbanDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IbanDetails {
  if (json == null) {
    return json;
  }
  return {
    iban: json['iban'],
    bankName: json['bankName'],
    bankBic: json['bankBic'],
  };
}

export function IbanDetailsToJSON(json: any): IbanDetails {
  return IbanDetailsToJSONTyped(json, false);
}

export function IbanDetailsToJSONTyped(value?: IbanDetails | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    iban: value['iban'],
    bankName: value['bankName'],
    bankBic: value['bankBic'],
  };
}
