/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KycVerificationStatus } from './KycVerificationStatus';
import {
  KycVerificationStatusFromJSON,
  KycVerificationStatusFromJSONTyped,
  KycVerificationStatusToJSON,
  KycVerificationStatusToJSONTyped,
} from './KycVerificationStatus';

/**
 *
 * @export
 * @interface KycInquiryResponse
 */
export interface KycInquiryResponse {
  /**
   *
   * @type {string}
   * @memberof KycInquiryResponse
   */
  inquiryId: string;
  /**
   *
   * @type {string}
   * @memberof KycInquiryResponse
   */
  uiContext: string;
  /**
   *
   * @type {KycVerificationStatus}
   * @memberof KycInquiryResponse
   */
  status: KycVerificationStatus;
}

/**
 * Check if a given object implements the KycInquiryResponse interface.
 */
export function instanceOfKycInquiryResponse(value: object): value is KycInquiryResponse {
  if (!('inquiryId' in value) || value['inquiryId'] === undefined) return false;
  if (!('uiContext' in value) || value['uiContext'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function KycInquiryResponseFromJSON(json: any): KycInquiryResponse {
  return KycInquiryResponseFromJSONTyped(json, false);
}

export function KycInquiryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycInquiryResponse {
  if (json == null) {
    return json;
  }
  return {
    inquiryId: json['inquiryId'],
    uiContext: json['uiContext'],
    status: KycVerificationStatusFromJSON(json['status']),
  };
}

export function KycInquiryResponseToJSON(json: any): KycInquiryResponse {
  return KycInquiryResponseToJSONTyped(json, false);
}

export function KycInquiryResponseToJSONTyped(
  value?: KycInquiryResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    inquiryId: value['inquiryId'],
    uiContext: value['uiContext'],
    status: KycVerificationStatusToJSON(value['status']),
  };
}
