/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const CryptoTransferType = {
  Tp: 'TP',
  User: 'USER',
} as const;
export type CryptoTransferType = (typeof CryptoTransferType)[keyof typeof CryptoTransferType];

export function instanceOfCryptoTransferType(value: any): boolean {
  for (const key in CryptoTransferType) {
    if (Object.prototype.hasOwnProperty.call(CryptoTransferType, key)) {
      if (CryptoTransferType[key as keyof typeof CryptoTransferType] === value) {
        return true;
      }
    }
  }
  return false;
}

export function CryptoTransferTypeFromJSON(json: any): CryptoTransferType {
  return CryptoTransferTypeFromJSONTyped(json, false);
}

export function CryptoTransferTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransferType {
  return json as CryptoTransferType;
}

export function CryptoTransferTypeToJSON(value?: CryptoTransferType | null): any {
  return value as any;
}

export function CryptoTransferTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): CryptoTransferType {
  return value as CryptoTransferType;
}
