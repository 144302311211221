/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionLiquidityProvider } from './TransactionLiquidityProvider';
import {
  TransactionLiquidityProviderFromJSON,
  TransactionLiquidityProviderFromJSONTyped,
  TransactionLiquidityProviderToJSON,
  TransactionLiquidityProviderToJSONTyped,
} from './TransactionLiquidityProvider';

/**
 *
 * @export
 * @interface TransactionOffer
 */
export interface TransactionOffer {
  /**
   *
   * @type {string}
   * @memberof TransactionOffer
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TransactionOffer
   */
  transactionId: string;
  /**
   *
   * @type {string}
   * @memberof TransactionOffer
   */
  paymentMethod: string;
  /**
   *
   * @type {TransactionLiquidityProvider}
   * @memberof TransactionOffer
   */
  liquidityProvider: TransactionLiquidityProvider;
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof TransactionOffer
   */
  processingFee: string;
  /**
   * Crypto currency with optional network identifier attached.
   * List of crypto currencies Simplex might send you:
   * - USDT
   * - TRX
   * - BTC
   * - USDP
   * - AAVE
   * - USDC
   * - UNI
   * - COTI-ERC20
   * - TUSD
   * - COTI
   * - BUSD
   * - HTR
   * - USDT-TRC20
   * @type {string}
   * @memberof TransactionOffer
   */
  cryptoCurrency: string;
  /**
   * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
   * @type {string}
   * @memberof TransactionOffer
   */
  fiatCurrency: string;
  /**
   * crypto amount. In major units. I.e. 3.4565432344
   * @type {string}
   * @memberof TransactionOffer
   */
  cryptoAmount: string;
  /**
   *
   * @type {number}
   * @memberof TransactionOffer
   */
  cryptoDecimals: number;
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof TransactionOffer
   */
  totalAmount: string;
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof TransactionOffer
   */
  totalAmountWithoutFees: string;
  /**
   * Crypto icon URL
   * @type {string}
   * @memberof TransactionOffer
   */
  cryptoCurrencyLogoUrl: string;
  /**
   * Crypto exchange rate
   * @type {string}
   * @memberof TransactionOffer
   */
  exchangeRate: string;
  /**
   *
   * @type {string}
   * @memberof TransactionOffer
   */
  processingTime: TransactionOfferProcessingTime;
}

/**
 * @export
 */
export const TransactionOfferProcessingTime = {
  Instant: 'instant',
  Fast: 'fast',
  Slow: 'slow',
} as const;
export type TransactionOfferProcessingTime =
  (typeof TransactionOfferProcessingTime)[keyof typeof TransactionOfferProcessingTime];

/**
 * Check if a given object implements the TransactionOffer interface.
 */
export function instanceOfTransactionOffer(value: object): value is TransactionOffer {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
  if (!('paymentMethod' in value) || value['paymentMethod'] === undefined) return false;
  if (!('liquidityProvider' in value) || value['liquidityProvider'] === undefined) return false;
  if (!('processingFee' in value) || value['processingFee'] === undefined) return false;
  if (!('cryptoCurrency' in value) || value['cryptoCurrency'] === undefined) return false;
  if (!('fiatCurrency' in value) || value['fiatCurrency'] === undefined) return false;
  if (!('cryptoAmount' in value) || value['cryptoAmount'] === undefined) return false;
  if (!('cryptoDecimals' in value) || value['cryptoDecimals'] === undefined) return false;
  if (!('totalAmount' in value) || value['totalAmount'] === undefined) return false;
  if (!('totalAmountWithoutFees' in value) || value['totalAmountWithoutFees'] === undefined) return false;
  if (!('cryptoCurrencyLogoUrl' in value) || value['cryptoCurrencyLogoUrl'] === undefined) return false;
  if (!('exchangeRate' in value) || value['exchangeRate'] === undefined) return false;
  if (!('processingTime' in value) || value['processingTime'] === undefined) return false;
  return true;
}

export function TransactionOfferFromJSON(json: any): TransactionOffer {
  return TransactionOfferFromJSONTyped(json, false);
}

export function TransactionOfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionOffer {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    transactionId: json['transactionId'],
    paymentMethod: json['paymentMethod'],
    liquidityProvider: TransactionLiquidityProviderFromJSON(json['liquidityProvider']),
    processingFee: json['processingFee'],
    cryptoCurrency: json['cryptoCurrency'],
    fiatCurrency: json['fiatCurrency'],
    cryptoAmount: json['cryptoAmount'],
    cryptoDecimals: json['cryptoDecimals'],
    totalAmount: json['totalAmount'],
    totalAmountWithoutFees: json['totalAmountWithoutFees'],
    cryptoCurrencyLogoUrl: json['cryptoCurrencyLogoUrl'],
    exchangeRate: json['exchangeRate'],
    processingTime: json['processingTime'],
  };
}

export function TransactionOfferToJSON(json: any): TransactionOffer {
  return TransactionOfferToJSONTyped(json, false);
}

export function TransactionOfferToJSONTyped(
  value?: TransactionOffer | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    transactionId: value['transactionId'],
    paymentMethod: value['paymentMethod'],
    liquidityProvider: TransactionLiquidityProviderToJSON(value['liquidityProvider']),
    processingFee: value['processingFee'],
    cryptoCurrency: value['cryptoCurrency'],
    fiatCurrency: value['fiatCurrency'],
    cryptoAmount: value['cryptoAmount'],
    cryptoDecimals: value['cryptoDecimals'],
    totalAmount: value['totalAmount'],
    totalAmountWithoutFees: value['totalAmountWithoutFees'],
    cryptoCurrencyLogoUrl: value['cryptoCurrencyLogoUrl'],
    exchangeRate: value['exchangeRate'],
    processingTime: value['processingTime'],
  };
}
