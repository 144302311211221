/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * crypto liquidation process status
 * @export
 */
export const TransactionCryptoLiquidationProcessStatus = {
  Pending: 'pending',
  Initiated: 'initiated',
  AgreementRequired: 'agreement_required',
  AgreementApproved: 'agreement_approved',
  AgreementRejected: 'agreement_rejected',
  Completed: 'completed',
  Rejected: 'rejected',
} as const;
export type TransactionCryptoLiquidationProcessStatus =
  (typeof TransactionCryptoLiquidationProcessStatus)[keyof typeof TransactionCryptoLiquidationProcessStatus];

export function instanceOfTransactionCryptoLiquidationProcessStatus(value: any): boolean {
  for (const key in TransactionCryptoLiquidationProcessStatus) {
    if (Object.prototype.hasOwnProperty.call(TransactionCryptoLiquidationProcessStatus, key)) {
      if (
        TransactionCryptoLiquidationProcessStatus[key as keyof typeof TransactionCryptoLiquidationProcessStatus] ===
        value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function TransactionCryptoLiquidationProcessStatusFromJSON(
  json: any,
): TransactionCryptoLiquidationProcessStatus {
  return TransactionCryptoLiquidationProcessStatusFromJSONTyped(json, false);
}

export function TransactionCryptoLiquidationProcessStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionCryptoLiquidationProcessStatus {
  return json as TransactionCryptoLiquidationProcessStatus;
}

export function TransactionCryptoLiquidationProcessStatusToJSON(
  value?: TransactionCryptoLiquidationProcessStatus | null,
): any {
  return value as any;
}

export function TransactionCryptoLiquidationProcessStatusToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): TransactionCryptoLiquidationProcessStatus {
  return value as TransactionCryptoLiquidationProcessStatus;
}
