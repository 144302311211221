/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionTrafficProviderConfig } from './TransactionTrafficProviderConfig';
import {
  TransactionTrafficProviderConfigFromJSON,
  TransactionTrafficProviderConfigFromJSONTyped,
  TransactionTrafficProviderConfigToJSON,
  TransactionTrafficProviderConfigToJSONTyped,
} from './TransactionTrafficProviderConfig';

/**
 *
 * @export
 * @interface TransactionTrafficProvider
 */
export interface TransactionTrafficProvider {
  /**
   * Liquidity provider name
   * @type {string}
   * @memberof TransactionTrafficProvider
   */
  name: string;
  /**
   * Traffic provider URL to return when transaction succeeded
   * @type {string}
   * @memberof TransactionTrafficProvider
   */
  successUrl: string;
  /**
   * Traffic provider URL to return when transaction failed
   * @type {string}
   * @memberof TransactionTrafficProvider
   */
  failureUrl: string;
  /**
   * Traffic provider logo URL
   * @type {string}
   * @memberof TransactionTrafficProvider
   */
  logo?: string;
  /**
   *
   * @type {TransactionTrafficProviderConfig}
   * @memberof TransactionTrafficProvider
   */
  config?: TransactionTrafficProviderConfig;
}

/**
 * Check if a given object implements the TransactionTrafficProvider interface.
 */
export function instanceOfTransactionTrafficProvider(value: object): value is TransactionTrafficProvider {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('successUrl' in value) || value['successUrl'] === undefined) return false;
  if (!('failureUrl' in value) || value['failureUrl'] === undefined) return false;
  return true;
}

export function TransactionTrafficProviderFromJSON(json: any): TransactionTrafficProvider {
  return TransactionTrafficProviderFromJSONTyped(json, false);
}

export function TransactionTrafficProviderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionTrafficProvider {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    successUrl: json['successUrl'],
    failureUrl: json['failureUrl'],
    logo: json['logo'] == null ? undefined : json['logo'],
    config: json['config'] == null ? undefined : TransactionTrafficProviderConfigFromJSON(json['config']),
  };
}

export function TransactionTrafficProviderToJSON(json: any): TransactionTrafficProvider {
  return TransactionTrafficProviderToJSONTyped(json, false);
}

export function TransactionTrafficProviderToJSONTyped(
  value?: TransactionTrafficProvider | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value['name'],
    successUrl: value['successUrl'],
    failureUrl: value['failureUrl'],
    logo: value['logo'],
    config: TransactionTrafficProviderConfigToJSON(value['config']),
  };
}
