/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountDetailsWebSessionsInner
 */
export interface AccountDetailsWebSessionsInner {
  /**
   * IPv4 of end-user’s device
   * @type {string}
   * @memberof AccountDetailsWebSessionsInner
   */
  ip: string;
  /**
   * Timestamp of session start
   * @type {number}
   * @memberof AccountDetailsWebSessionsInner
   */
  timestamp: number;
  /**
   * The User-Agent HTTP header sent by the end-user’s browser
   * @type {string}
   * @memberof AccountDetailsWebSessionsInner
   */
  userAgent?: string;
  /**
   * The value of a per-device tracking cookie that is managed by you. That is to say: equal uaid‘s mean 'same end-user device
   * @type {string}
   * @memberof AccountDetailsWebSessionsInner
   */
  uaid?: string;
  /**
   * The Accept-Language HTTP header sent by the end-user’s browser
   * @type {string}
   * @memberof AccountDetailsWebSessionsInner
   */
  httpAcceptLanguage?: string;
}

/**
 * Check if a given object implements the AccountDetailsWebSessionsInner interface.
 */
export function instanceOfAccountDetailsWebSessionsInner(value: object): value is AccountDetailsWebSessionsInner {
  if (!('ip' in value) || value['ip'] === undefined) return false;
  if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
  return true;
}

export function AccountDetailsWebSessionsInnerFromJSON(json: any): AccountDetailsWebSessionsInner {
  return AccountDetailsWebSessionsInnerFromJSONTyped(json, false);
}

export function AccountDetailsWebSessionsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccountDetailsWebSessionsInner {
  if (json == null) {
    return json;
  }
  return {
    ip: json['ip'],
    timestamp: json['timestamp'],
    userAgent: json['user_agent'] == null ? undefined : json['user_agent'],
    uaid: json['uaid'] == null ? undefined : json['uaid'],
    httpAcceptLanguage: json['http_accept_language'] == null ? undefined : json['http_accept_language'],
  };
}

export function AccountDetailsWebSessionsInnerToJSON(json: any): AccountDetailsWebSessionsInner {
  return AccountDetailsWebSessionsInnerToJSONTyped(json, false);
}

export function AccountDetailsWebSessionsInnerToJSONTyped(
  value?: AccountDetailsWebSessionsInner | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    ip: value['ip'],
    timestamp: value['timestamp'],
    user_agent: value['userAgent'],
    uaid: value['uaid'],
    http_accept_language: value['httpAcceptLanguage'],
  };
}
