/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TransferInstructionStatus = {
  Pending: 'PENDING',
  Initiated: 'INITIATED',
  Completed: 'COMPLETED',
  TimedOut: 'TIMED_OUT',
} as const;
export type TransferInstructionStatus = (typeof TransferInstructionStatus)[keyof typeof TransferInstructionStatus];

export function instanceOfTransferInstructionStatus(value: any): boolean {
  for (const key in TransferInstructionStatus) {
    if (Object.prototype.hasOwnProperty.call(TransferInstructionStatus, key)) {
      if (TransferInstructionStatus[key as keyof typeof TransferInstructionStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function TransferInstructionStatusFromJSON(json: any): TransferInstructionStatus {
  return TransferInstructionStatusFromJSONTyped(json, false);
}

export function TransferInstructionStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransferInstructionStatus {
  return json as TransferInstructionStatus;
}

export function TransferInstructionStatusToJSON(value?: TransferInstructionStatus | null): any {
  return value as any;
}

export function TransferInstructionStatusToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): TransferInstructionStatus {
  return value as TransferInstructionStatus;
}
