/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const KycCollectionStatus = {
  InProgress: 'in_progress',
  Completed: 'completed',
} as const;
export type KycCollectionStatus = (typeof KycCollectionStatus)[keyof typeof KycCollectionStatus];

export function instanceOfKycCollectionStatus(value: any): boolean {
  for (const key in KycCollectionStatus) {
    if (Object.prototype.hasOwnProperty.call(KycCollectionStatus, key)) {
      if (KycCollectionStatus[key as keyof typeof KycCollectionStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function KycCollectionStatusFromJSON(json: any): KycCollectionStatus {
  return KycCollectionStatusFromJSONTyped(json, false);
}

export function KycCollectionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycCollectionStatus {
  return json as KycCollectionStatus;
}

export function KycCollectionStatusToJSON(value?: KycCollectionStatus | null): any {
  return value as any;
}

export function KycCollectionStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): KycCollectionStatus {
  return value as KycCollectionStatus;
}
