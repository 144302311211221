/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TransactionLiquidityProvider
 */
export interface TransactionLiquidityProvider {
  /**
   * Liquidity provider identifier
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  id: string;
  /**
   * Liquidity provider name
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  name: string;
  /**
   * Liquidity provider title, used in hamburger menu footer block
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  title: string;
  /**
   * Liquidity provider address, used in hamburger menu footer block
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  address: string;
  /**
   * Liquidity provider url
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  url?: string;
  /**
   * Liquidity provider terms and conditions url
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  termsAndConditionsUrl: string;
  /**
   * Liquidity provider privacy url
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  privacyPolicyUrl: string;
  /**
   * Liquidity provider and Simplex combined terms and conditions url
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  termsAndConditionsCombinedUrl: string;
  /**
   * Liquidity provider and Simplex combined privacy url
   * @type {string}
   * @memberof TransactionLiquidityProvider
   */
  privacyPolicyCombinedUrl: string;
}

/**
 * Check if a given object implements the TransactionLiquidityProvider interface.
 */
export function instanceOfTransactionLiquidityProvider(value: object): value is TransactionLiquidityProvider {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('title' in value) || value['title'] === undefined) return false;
  if (!('address' in value) || value['address'] === undefined) return false;
  if (!('termsAndConditionsUrl' in value) || value['termsAndConditionsUrl'] === undefined) return false;
  if (!('privacyPolicyUrl' in value) || value['privacyPolicyUrl'] === undefined) return false;
  if (!('termsAndConditionsCombinedUrl' in value) || value['termsAndConditionsCombinedUrl'] === undefined) return false;
  if (!('privacyPolicyCombinedUrl' in value) || value['privacyPolicyCombinedUrl'] === undefined) return false;
  return true;
}

export function TransactionLiquidityProviderFromJSON(json: any): TransactionLiquidityProvider {
  return TransactionLiquidityProviderFromJSONTyped(json, false);
}

export function TransactionLiquidityProviderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionLiquidityProvider {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    title: json['title'],
    address: json['address'],
    url: json['url'] == null ? undefined : json['url'],
    termsAndConditionsUrl: json['termsAndConditionsUrl'],
    privacyPolicyUrl: json['privacyPolicyUrl'],
    termsAndConditionsCombinedUrl: json['termsAndConditionsCombinedUrl'],
    privacyPolicyCombinedUrl: json['privacyPolicyCombinedUrl'],
  };
}

export function TransactionLiquidityProviderToJSON(json: any): TransactionLiquidityProvider {
  return TransactionLiquidityProviderToJSONTyped(json, false);
}

export function TransactionLiquidityProviderToJSONTyped(
  value?: TransactionLiquidityProvider | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    name: value['name'],
    title: value['title'],
    address: value['address'],
    url: value['url'],
    termsAndConditionsUrl: value['termsAndConditionsUrl'],
    privacyPolicyUrl: value['privacyPolicyUrl'],
    termsAndConditionsCombinedUrl: value['termsAndConditionsCombinedUrl'],
    privacyPolicyCombinedUrl: value['privacyPolicyCombinedUrl'],
  };
}
